<template>
  <div class="main">
    <div class="tab">
      <span @click="show=true;recruit_type = 0" :class="{ active: show}">招募写手</span>
      <span @click="show=false;recruit_type = 1" :class="{ active: !show}">招募主播</span>
    </div>
    <main>
      <div class="box" v-if="show">
        <img :src="require('@/assets/img1.jpg')">
        <img :src="require('@/assets/img2.jpg')">
        <img :src="require('@/assets/img3.jpg')">
      </div>
      <div class="box" v-else>
        <img :src="require('@/assets/post1.jpg')">
        <img :src="require('@/assets/post2.jpg')">
        <img :src="require('@/assets/post3.jpg')">
        <img :src="require('@/assets/post4.jpg')">
      </div>
    </main>
    <footer v-if="show">
      <div @click="showConfirm = true"><img :src="require('@/assets/submit.png')"></div>
    </footer>
    <div @click="showConfirm = true" class="button" v-else>
      <img :src="require('@/assets/submit1.jpg')">
    </div>
    <VanPopup v-model="showConfirm" round closeable class="confirm" :class="{ active: !show}">
      <h2 v-if="show">填写注册信息</h2>
      <h2 v-else></h2>
      <VanField v-model="from.nick" placeholder="昵称（笔名）"/>
      <VanField v-model="from.email" placeholder="邮箱"/>
      <VanField v-model="from.qq" placeholder="QQ" type="digit"/>
      <VanField v-model="from.phoneNum" placeholder="手机号" type="tel"/>
      <VanField v-model="from.phoneNum2" placeholder="其他联系方式"/>
      <VanField v-model="from.time" placeholder="方便联系时间" readonly @click="timeClick"/>
      <button @click="submit">提交</button>
    </VanPopup>
    <VanPopup v-model="timeShow" position="bottom" :style="{ height: '45%' }">
      <VanDatetimePicker
          @cancel="timeShow = false"
          @confirm="confirmTime"
          v-model="time"
          type="datetime"
          title="选择完整时间"
          :min-date="minDate"
      />
    </VanPopup>
  </div>
</template>

<script>
import {DatetimePicker as VanDatetimePicker, Field as VanField, Popup as VanPopup, Toast} from 'vant';
import axios from "axios"

export default {
  name: "Recruit",
  components: {
    VanPopup,
    VanField,
    VanDatetimePicker
  },
  data() {
    return {
      show:true,
      showConfirm: false,
      from: {
        nick: '',
        email: '',
        qq: '',
        phoneNum: '',
        phoneNum2: '',
        time: '',
      },
      recruit_type:0,
      timeShow: false,
      time: new Date(),
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    }
  },
  watch: {
    showConfirm(val, preVal) {
      if (!val){
        this.from = {
          nick: '',
          email: '',
          qq: '',
          phoneNum: '',
          phoneNum2: '',
          time: '',
        }
      }
    }
  },
  methods: {
    submit() {
      let bool = false
      for (const key in this.from) {
        if (this.from[key] != '') {
          bool = true
          break
        }
      }

      if (!bool) {
        Toast("不可提交空信息")
      } else {
        if (this.from.email != '' && !this.validatorEmail(this.from.email)) {
          Toast("请输入正确邮箱")
          return
        }
        let time = localStorage.getItem("submit_time")
        let data = JSON.parse(time || '{}')
        let nowTime = parseInt(new Date().getTime() / 1000)
        let countTtime = nowTime - data.time

        if (time && countTtime <= 60 && data.count >= 3) {
          Toast("操作频繁，请稍后再试")
        } else {
          Toast.loading({
            message: '提交中...',
            forbidClick: true,
            duration: 0,
          });
          axios.post('https://api.byfm2.app/api/add_staff', {
            nick_name: this.from.nick,
            email_address: this.from.email,
            qq_number: this.from.qq,
            mobile: this.from.phoneNum,
            other_contact: this.from.phoneNum2,
            contact_date_time: this.from.time,
            recruit_type:this.recruit_type
          }).then(res => {
            console.log(res)
            Toast(res.data.msg)
            if (res.data.code == 200) {
              this.showConfirm = false
            }

            let count = 1
            if (time != null && countTtime <= 60) {
              count = data.count
              count += 1
            }

            localStorage.setItem("submit_time", JSON.stringify({time: parseInt(new Date().getTime() / 1000), count}))
          }).catch(err => {
            Toast("提交失败")
          })
        }
      }
    },
    validatorEmail(val) {
      return /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(val)
    },
    timeClick() {
      this.timeShow = true
    },
    formatter(val = null, fmt = "yyyy-MM-dd hh:mm:ss") {
      let _this = new Date(val)
      var o = {
        "M+": _this.getMonth() + 1, //月份
        "d+": _this.getDate(), //日
        "h+": _this.getHours(), //小时
        "m+": _this.getMinutes(), //分
        "s+": _this.getSeconds(), //秒
        "q+": Math.floor((_this.getMonth() + 3) / 3), //季度
        "S": _this.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (_this.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    },
    confirmTime(value) {
      this.from.time = this.formatter(value)
      this.timeShow = false
    }
  }
}
</script>

<style scoped lang="scss">

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

main {
  flex: 1;
  overflow: auto;

  img {
    width: 100%;
  }
}
.box{
  padding-top: 40px;
}
footer {
  height: (177/2)+px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(117deg, #25275a 0%, #111329 100%);

  img {
    width: (451/2)+px;
    height: (88/2)+px;
  }
}

.confirm {
  width: (679/2)+px;
  height: (926/2)+px;
  background: #FFFFFF;
  border-radius: (41/2)+px;
  text-align: center;

  h2 {
    margin-top: (80/2)+px;
    font-size: (36/2)+px;
    color: #FF6000;
    font-weight: 600;
    margin-bottom: (40/2)+px;
  }

  button {
    margin-top: (40/2)+px;
    width: (644/2)+px;
    height: (110/2)+px;
    background-image: linear-gradient(152deg, #FFBD5C 0%, rgba(255, 47, 0, 0.82) 100%);
    border-radius: (55/2)+px;
    border: none;

    font-size: (36/2)+px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 600;
  }
}
.tab{
    display: flex;
    position: fixed;
    justify-content: center;
    height: 40px;
    line-height: 40px;
    background: #1e1e1e;
    color: #fff;
    width: 375px;
    text-align: center;
    z-index: 99;
    font-size: 21px;
    
    span{
        display: block;
        width: 100%;
    }
    .active{
        background: #1b1b1b;
        color: #efcf99;
        border-bottom: 2px solid #efcf99;
    }
}
.button{
  width: 100%;
  padding-top: 20px;
  background: #0d0c0c;
  img{
    width: 100%;
    height: 100%;
  }
}
.active{
  background: url('../assets/pop_bg.png') no-repeat 100%/100%;
  border-radius: 0;
  .van-cell{
    width: 92%;
    margin: 10px auto;
    border-radius: 5px;
  }
  .van-cell:nth-child(2){
    margin-top:50px ;
  }
  button{
    margin: 10px 0;
  }
}
</style>
